import React, { Component } from "react";

class Map extends Component {

   
  render() {
    const { 
      props: { locations, map }
    } = this;

  
    return (
      <div className="map">
        <img alt="map" className="map__background" src={(map)} />
        <div  id="map"  className="map__locations">
        {locations.map((location, i) => {
          return (
            <div key={i} className="map__location" onClick={() => window.openModal('modalLocation'+i)} style={{top: location.top+'%', left: location.left+'%'}}>
              <img
                      src={require("../images/elements/pin_single.svg")}
                      alt="Pin"
                      
                    />
            </div>
          );
        })}
        </div>
      </div>
    );
  }
};

export default Map;
